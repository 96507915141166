<template>
  <div class="home-box" v-if="userInfo">
    <van-nav-bar title="个人中心" />
    <div class="info">
      <img :src="userInfo.companyVO.avatarUrl" alt />
      <div>{{ userInfo.userSelfhelpVO.userName }}</div>
    </div>
    <van-cell-group>
      <van-cell v-for="(el, i) in cellData" :key="i" is-link @click="goPage(el.path)">
        <template #title>
          <div class="list_title_box">
            <img class="custom-img" :src="require(`../../assets/myHome/${el.icon}.png`)" alt />
            <span class="custom-title">{{ el.name }}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="login-out-box">
      <van-button plain round block @click="loginOut">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'myHome',
  data() {
    return {
      cellData: [
        {
          name: '账号设置',
          icon: 'setting',
          path: '/myHome/setting',
        },
        {
          name: '反馈周期配置',
          icon: 'feedback_cycle',
          path: '/myHome/feedbackCycle',
        },
        {
          name: '企业信息',
          icon: 'company_info',
          path: '/myHome/companyInfo',
        },
        {
          name: '修改绑定企业',
          icon: 'company_info',
          path: '/myHome/editInfo',
        },
        {
          name: '简历库',
          icon: 'awaiting',
          path: '/myHome/resumeLibrary',
        },
        {
          name: '数据看板',
          icon: 'data_view',
          path: '/myHome/dataView',
        },
      ],
      userInfo: null,
    };
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
  },
  methods: {
    ...mapMutations('user', ['SET_ISLOGIN']),
    goPage(path) {
      this.$router.push(path);
    },
    loginOut() {
      localStorage.setItem('userInfo', '');
      localStorage.setItem('accessToken', '');
      this.$router.push('/login');
      this.SET_ISLOGIN(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-box {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .info {
    display: flex;
    align-items: center;
    margin: 8px 0 32px 20px;
    img {
      height: 55px;
      width: 55px;
      border-radius: 50%;
      margin-right: 18px;
    }
    div {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .list_title_box {
    display: flex;
    .custom-img {
      height: 24px;
      width: 24px;
    }
    .custom-title {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-left: 17px;
    }
  }
  .login-out-box {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding: 0 96px;
    .van-button__text {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
